import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { SECRET_SLUG } from 'api/groups/constants';
import { WixGroupsController } from 'controllers/controller';
import { actions } from 'store/application';

import settingsParams from './settingsParams';

const createController: CreateControllerFn = async (params) => {
  const { flowAPI } = params;

  const controller = new WixGroupsController(params);

  controller.updateConfig = handleUpdateConfig;
  controller.handleTransition = handleTransition;

  return controller;

  async function handleTransition() {
    const [groupId] = await Promise.all([
      await controller.vm.group$
        .fetch(flowAPI.settings.get(settingsParams.groupId))
        .unwrap()
        .then(({ group }) => group.id),

      controller.vm.application$.fetchUserProfile(),
      controller.vm._.comments.init(),
    ]);

    controller.store.dispatch(actions.setAppData({ groupId }));

    await controller.vm.feed$.fetch(groupId as string, {
      limit: flowAPI.settings.get(settingsParams.postsCount),
    });

    return {};
  }

  function handleUpdateConfig() {
    const groupId = flowAPI.settings.get(settingsParams.groupId);

    if (groupId !== SECRET_SLUG) {
      controller.store.dispatch(actions.setAppData({ groupId }));
    }
  }
};

export default createController;
